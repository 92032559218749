export const TOWER_RADIUS = 4;

export const TOWER_POSITION = {
    x: 0,
    y: 0,
    z: 0,
};

export const TOWER_HEIGHT = 11;

export const TOWER_ATTACK_RANGE = 10;
